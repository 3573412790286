import ss1 from "../images/ss1.png";
import ss2 from "../images/ss2.png";
import ss3 from "../images/ss3.png";
const images = [ss1, ss2, ss3];

const slides = [
  <div className="slide">
    <div className="inner_slide_container">
      <img className="inner_slide_image" src={images[0]} alt="" />
      <div className="inner_slide_textbox">
        <h4 className="inner_slide_header">AJ</h4>
        <p className="inner_slide_text">
          Origin and Education <br />
          <br />
          AJ Al-Bawab, a Summa Cum Laude graduate with a Bachelor of Business
          Administration from Georgia State University, is a finance
          professional with a strong focus on systems, problem-solving, and
          strategic thinking. <br />
          <br />
          Originally from Dubai, AJ brings a unique perspective that blends
          intercultural empathy with an analytical mindset, allowing him to
          navigate and understand diverse risk profiles. His passion for
          financial analysis stems from a desire to help individuals and
          businesses thrive, leveraging his creativity, efficiency, and logical
          approach to drive impactful solutions.
        </p>
      </div>
    </div>
  </div>,
  <div className="slide">
    <div className="inner_slide_container">
      <img className="inner_slide_image" src={images[1]} alt="" />
      <div className="inner_slide_textbox">
        <h4 className="inner_slide_header">AJ</h4>
        <p className="inner_slide_text">
          Finance Professional and Lifelong Learner <br />
          <br />
          AJ Al-Bawab’s curiosity and ambition shape his daily pursuits.
          Alongside his full-time role at Truist, AJ actively serves as an Audio
          Engineer at his local church, mentors aspiring finance students, and
          continues to develop his coding expertise through personal projects,
          including this website and his passion project, an iPhone app. AJ is
          also committed to fostering meaningful professional relationships and
          continuously expanding his skills in finance and technology.
        </p>
      </div>
    </div>
  </div>,
  <div className="slide">
    <div className="inner_slide_container">
      <img className="inner_slide_image" src={images[2]} alt="" />
      <div className="inner_slide_textbox">
        <h4 className="inner_slide_header">AJ</h4>
        <p className="inner_slide_text">
          Continuous Growth and Intellectual Curiosity <br />
          <br />
          AJ Al-Bawab's blend of drive, curiosity, and intuition gives him a
          distinctive edge, serving as his "secret sauce" for success. He
          remains committed to growing intellectually every day, continuously
          learning from those around him and embracing new challenges every day.{" "}
          <br />
          <br />
        </p>
      </div>
    </div>
  </div>,
];

export default slides;
