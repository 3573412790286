import React from "react";
import { Routes, Route } from "react-router-dom/dist";
import Portal from "../pages/portal";
import Home from "../pages/home";
import Calendar from "../pages/calendar";
import Schedule from "../pages/schedule";

const Main = () => {
  return (
    <Routes>
      <Route path="/portal" element={<Portal />} />
      <Route path="/" element={<Home />} />
      <Route path="/calendar" element={<Calendar />} />
      <Route path="/schedule" element={<Schedule />} />
    </Routes>
  );
};

export default Main;
