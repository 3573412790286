import React, { useState, useEffect } from "react";
import SlideShow from "../components/slideshow";
import "../css/home.css";
import image2 from "../images/about_2.png";
import image3 from "../images/about_3.jpg";
import image4 from "../images/about_4.jpg";
import image5 from "../images/about_5.jpg";
import { BsLinkedin, BsInstagram, BsGithub } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import { FaAddressCard } from "react-icons/fa";
import textInput from "../about_hardcode";
import NavBar from "../components/navBar";

const Home = () => {
  const [contentAbout] = useState([{ ...textInput }]);

  const [headerStyles, setHeaderStyles] = useState([
    { left: "30vw", color: "black" }, // First header
    { left: "60vw", color: "black" }, // Second header
    { left: "120vw", color: "black" }, // Third header
    { left: "190vw", color: "black" }, // Fourth header
  ]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPos = window.scrollY;
      const newStyles = [...headerStyles];

      // Handle first header
      const calculateOpacity = (scrollPos, start, end) => {
        // If scrollPos is before the start of the transition, return 0 (fully transparent)
        if (scrollPos <= start) return 0;

        // If scrollPos is beyond the end of the transition, return 1 (fully white)
        if (scrollPos >= end) return 1;

        // Calculate the opacity based on the scroll position
        return (scrollPos - start) / (end - start);
      };

      // Mission and Goal
      newStyles[0] = {
        left: `${Math.max(20 - scrollPos / 20, 0)}vw`,
        color: `rgba(255, 255, 255, ${calculateOpacity(scrollPos, 200, 300)})`, // Opacity changes between scrollPos 100 and 300
      };

      // Upbringing
      newStyles[1] = {
        left: `${Math.max(60 - scrollPos / 20, 0)}vw`,
        color: `rgba(255, 255, 255, ${calculateOpacity(scrollPos, 900, 1000)})`, // Opacity changes between scrollPos 400 and 600
      };

      //Professional
      newStyles[2] = {
        left: `${Math.max(110 - scrollPos / 20, 0)}vw`,
        color: `rgba(255, 255, 255, ${calculateOpacity(
          scrollPos,
          2000,
          2300
        )})`, // Opacity changes between scrollPos 800 and 1000
      };

      //Hobbies
      newStyles[3] = {
        left: `${Math.max(210 - scrollPos / 20, 0)}vw`,
        color: `rgba(255, 255, 255, ${calculateOpacity(
          scrollPos,
          3800,
          4000
        )})`, // Opacity changes between scrollPos 1600 and 1800
      };

      setHeaderStyles(newStyles);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [headerStyles]);

  const CreateVCard = () => {
    var vCardsJS = require("vcards-js");

    var vCard = vCardsJS();
    vCard.firstName = "AJ";
    vCard.lastName = "Al-Bawab";
    vCard.organization = "Truist Securities";
    vCard.photo.attachFromUrl(
      "https://avatars.githubusercontent.com/u/68740075?v=4",
      "JPEG"
    );
    vCard.cellPhone = "678-447-6254";
    vCard.birthday = new Date(1995, 4, 11);
    vCard.title = "Credit Delivery Analyst";
    vCard.email = "ajalbawab@gmail.com";
    vCard.url = "https://ajalbawab.com/";
    vCard.note =
      "Chicken bowl, black beans, brown rice, fajita veggies, mild salsa, guacamole, and lettuce.";

    const FileSaver = require("file-saver");
    const blob = new Blob([vCard.getFormattedString()], {
      type: "text/vcard;charset=utf-8",
    });
    FileSaver.saveAs(blob, "ajalbawab.vcf");
  };

  return (
    <div style={{ backgroundColor: "#1a1a1a", marginBottom: -100 }}>
      <div style={{ width: "100%" }}>
        <NavBar />
      </div>

      <div className="header_section">
        <p className="header_title">AJ Al-Bawab</p>
        <div className="socials_container">
          <a
            href="https://www.linkedin.com/in/ajalbawab/"
            target="_blank"
            rel="noreferrer"
            className="social_icon"
            style={{ textDecoration: "none" }}
          >
            <BsLinkedin
              className="social linkedin"
              style={{ color: "white" }}
            />
            <BsLinkedin className="social linkedin_hide" />
            <p
              style={{
                color: "white",
                fontWeight: "bold",
                textDecoration: "none",
              }}
              className="icon_text"
            >
              LinkedIn
            </p>
          </a>
          <a
            href="https://www.instagram.com/ajalbawab/?hl=en"
            target="_blank"
            rel="noreferrer"
            className="social_icon"
            style={{ textDecoration: "none" }}
          >
            <BsInstagram
              className="social instagram"
              style={{ color: "white" }}
            />
            <BsInstagram className="social instagram_hide" />
            <p
              style={{
                color: "white",
                fontWeight: "bold",
                textDecoration: "none",
              }}
              className="icon_text"
            >
              Social
            </p>
          </a>
          <a
            href="https://github.com/ajalbawab"
            target="_blank"
            rel="noreferrer"
            className="social_icon"
            style={{ textDecoration: "none" }}
          >
            <BsGithub className="social github" style={{ color: "white" }} />
            <BsGithub className="social github_hide" />
            <p
              style={{
                color: "white",
                fontWeight: "bold",
                textDecoration: "none",
              }}
              className="icon_text"
            >
              Github
            </p>
          </a>
          <div onClick={() => CreateVCard()} className="social_icon">
            <FaAddressCard
              className="social vcard"
              style={{ color: "white" }}
            />
            <FaAddressCard
              className="social vcard_hide"
              style={{ color: "#0d6efd" }}
            />
            <p
              style={{
                color: "white",
                fontWeight: "bold",
                textDecoration: "none",
              }}
              className="icon_text"
            >
              eCard
            </p>
          </div>
          <a
            href="mailto:ajalbawab@gmail.com"
            target="_blank"
            rel="noreferrer"
            className="social_icon"
            style={{ textDecoration: "none" }}
          >
            <MdOutlineEmail
              className="social email"
              style={{ color: "white" }}
            />
            <MdOutlineEmail className="social email_hide" />
            <p
              style={{
                color: "white",
                fontWeight: "bold",
                textDecoration: "none",
              }}
              className="icon_text"
            >
              Email
            </p>
          </a>
        </div>
      </div>

      <div>
        <div className="section_2">
          <section className="section_2_block1">
            <img alt="" src={image2} className="clock_img"></img>
          </section>
          <section className="section_2_block2">
            <div className="vision_block_inside">
              <h1
                className="headerPage"
                style={{
                  position: "relative",
                  left: headerStyles[0].left,
                  color: headerStyles[0].color,
                  transition: "all 0.5s ease-out",
                }}
              >
                Mission and Goal
              </h1>

              {contentAbout[0].mission.split("\n\n").map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>
          </section>
        </div>

        <div className="section_3">
          <section className="section_3_block1">
            <div className="education_block_inside">
              <h1
                className="headerPage"
                style={{
                  position: "relative",
                  left: headerStyles[1].left,
                  color: headerStyles[1].color,
                  transition: "all 0.5s ease-out",
                }}
              >
                Upbringing and Education
              </h1>

              {contentAbout[0].upbringing
                .split("\n\n")
                .map((paragraph, index) => (
                  <p className="education_block2_p" key={index}>
                    {paragraph}
                  </p>
                ))}
            </div>
          </section>
          <section className="section_3_block2">
            <img alt="" src={image3} className="clock_img"></img>
          </section>{" "}
        </div>

        <div className="section_4">
          <section className="section_4_block1">
            <div className="education_block_inside">
              <h1
                className="headerPage"
                style={{
                  position: "relative",
                  left: headerStyles[2].left,
                  color: headerStyles[2].color,
                  transition: "all 0.5s ease-out",
                }}
              >
                Professional Experience
              </h1>

              {contentAbout[0].professional
                .split("\n\n")
                .map((paragraph, index) => (
                  <p className="education_block2_p" key={index}>
                    {paragraph}
                  </p>
                ))}
            </div>
          </section>
          <section className="section_4_block2">
            <img alt="" src={image4} className="clock_img"></img>
          </section>
        </div>

        <div className="section_5">
          <section className="section_5_block2">
            <img alt="" src={image5} className="clock_img"></img>
          </section>
          <section className="section_5_block1">
            <div className="education_block_inside">
              <h1
                className="headerPage"
                style={{
                  position: "relative",
                  left: headerStyles[3].left,
                  color: headerStyles[3].color,
                  transition: "all 0.5s ease-out",
                }}
              >
                Hobbies and Interests
              </h1>

              {contentAbout[0].upbringing
                .split("\n\n")
                .map((paragraph, index) => (
                  <p className="education_block2_p" key={index}>
                    {paragraph}
                  </p>
                ))}
            </div>
          </section>
        </div>
      </div>
      <SlideShow />
      <div style={{ height: 1 }} />
    </div>
  );
};

export default Home;
