import React from "react";
import NavBar from "../components/navBar";

class Schedule extends React.Component {
  componentDidMount() {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);
  }
  render() {
    return (
      <div>
        <NavBar />
        <div
          style={{
            height: "100vh",
            overflow: "hidden",
            position: "absolute",
            backgroundColor: "#1a1a1a",
            width: "100%",
            top: 0,
            zIndex: -2,
          }}
        />
        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/ajalbawab"
          style={{
            minWidth: "320px",
            position: "absolute",
            width: "100%",
            height: "90vh",
            zIndex: -1,
            marginTop: 6,
          }}
        />
      </div>
    );
  }
}

export default Schedule;
