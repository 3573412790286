import React from "react";
import PasswordCalendar from "../components/passwordCalendar";
import NavBar from "../components/navBar";

const Calendar = () => {
  return (
    <div>
      <div style={{ width: "100%" }}>
        <NavBar />
      </div>
      <PasswordCalendar />;
    </div>
  );
};

export default Calendar;
