import React, { useState } from "react";

import "../css/portal.css";
import NavBar from "../components/navBar";

const Portal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const Card = ({ url, imgSrc, title, description }) => {
    return (
      <div className="ccard" onClick={() => (window.location = url)}>
        <img className="homeimg" src={imgSrc} alt={title} />
        <article className="content">
          <h3 className="headers3">{title}</h3>
          <p className="paragraph_text">{description}</p>
        </article>
      </div>
    );
  };

  const Modal = () => (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Minecraft Server Address:</h2>
        <h2>minecraft.ajalbawab.com</h2>
        <p>Compatible with Java and Bedrock</p>
        <button onClick={closeModal}>Close</button>
      </div>
    </div>
  );

  return (
    <div>
      <div style={{ width: "100%" }}>
        <NavBar />
      </div>
      <div className="background" />
      {isModalOpen && <Modal />}
      <div className="center_container">
        <section className="main_container">
          <div
            className="ccard"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            <img className="homeimg personalAJ" src="headshot.png" alt="" />
            <article className="content">
              <h3 className="headers3">Personal Website</h3>
              <p className="paragraph_text">You're Here! Click Me.</p>
            </article>
          </div>

          <Card
            url="https://kaleau.com/"
            imgSrc="ASSET5.png"
            title="Kaleau"
            description="Take a look into the App I'm currently working on."
          />
          <Card
            url="https://networkvice.com/"
            imgSrc="cloud_service.png"
            title="Personal Drive"
            description="A personal cloud-storage network for minimizing CPU harddrive space."
          />
          <Card
            url="https://albawabthoughts.com"
            imgSrc="albawab_thoughts.png"
            title="Personal Blog"
            description="A simple / self hosted PHP blog."
          />
          <Card
            url="https://zabbix.ajalbawab.com"
            imgSrc="zabbix_logo.png"
            title="Zabbix"
            description="Personal Server Traffic Manager"
          />
          <Card
            url="https://kibana.ajalbawab.com/login?next=%2F"
            imgSrc="elasticstack.png"
            title="Kibana"
            description="Data Visualization Dashboard"
          />
          <Card
            url="https://warden.ajalbawab.com/#/login"
            imgSrc="vaultwarden.png"
            title="Password Manager"
            description="Open Source Password Manager"
          />
          <div className="ccard" onClick={() => openModal()}>
            <img
              className="homeimg"
              src={"minecraft.png"}
              alt={"Minecraft Server"}
            />
            <article className="content">
              <h3 className="headers3">{"Minecraft Server"}</h3>
              <p className="paragraph_text">{"My personal Minecraft server"}</p>
            </article>
          </div>
          <Card
            url="https://ai.ajalbawab.com"
            imgSrc="llama.png"
            title="Llama AI Chat Service"
            description="An AI chat service built from Meta's Llama3.1"
          />
          <Card
            url="https://web.ajalbawab.com"
            imgSrc="web.png"
            title="Search Engine"
            description="Search engine using SearXNG. Trackers not included."
          />
        </section>
      </div>
    </div>
  );
};

export default Portal;
