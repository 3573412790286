import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import iCalendarPlugin from "@fullcalendar/icalendar";
import googleCalendarPlugin from "@fullcalendar/google-calendar";

export default class DemoApp extends React.Component {
  state = {
    weekendsVisible: true,
    currentEvents: [],
  };

  render() {
    return (
      <div className="demo-app">
        {this.renderSidebar()}
        <div className="demo-app-main">
          <FullCalendar
            plugins={[
              dayGridPlugin,
              timeGridPlugin,

              iCalendarPlugin,
              googleCalendarPlugin,
            ]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            style={{ height: "100%" }}
            initialView="dayGridMonth"
            selectable={false}
            selectMirror={true}
            googleCalendarApiKey="AIzaSyANqGweUAICdB84tM0Pll5AFIY8aJtYXLU"
            dayMaxEvents={true}
            weekends={this.state.weekendsVisible}
            eventSources={[
              {
                url: "https://gist.githubusercontent.com/ajalbawab/0306ffb0f6b81e10860babc2af46842d/raw/a94a81721dac24eb26bec0710cc442a37ec3d9b8/basic.ics",
                format: "ics",
              },
              { googleCalendarId: "investorsatl@gmail.com" },
              {
                googleCalendarId:
                  "0lqrfcp8jkmua77412l8ki9hn9t2jtpr@import.calendar.google.com",
              },
              {
                googleCalendarId:
                  "49b2qf6thhp9mdkb349su6ka9ar6ml58@import.calendar.google.com",
              },
            ]}
          />
        </div>
      </div>
    );
  }

  renderSidebar() {
    return (
      <div className="demo-app-sidebar">
        <div className="demo-app-sidebar-section"></div>
      </div>
    );
  }
}
