const textInput = {
  mission: `One of my greatest personal challenges has been grappling with the nature of time—how it seems to move faster as we grow older. My mission is to create a life where I can afford time—time to savor the moments that matter most. Each day, I strive to learn something new, embracing the opportunity to grow and experience life’s richness, one moment at a time.`,
  upbringing: `After high school, I began volunteering at a local church, learning the ropes of audio/visual production for services. What started as a volunteer role quickly ignited a passion, leading me to enroll at SAE, an audio school in downtown Atlanta. I dedicated myself to commuting an hour and a half each way to pursue this new path while contracting at the same church.


Over time, this passion evolved into a thriving career. I relied financially on contract work for large corporate events, weekly services, and even national tours with country radio artists. While I loved every moment of this journey, the weight of financial pressure began to build.

In 2017, I decided to shift gears and enrolled full-time in Finance at the University of North Georgia, all while maintaining my audio career and traveling across the U.S. The analytical and technical mindset I developed in audio served me well in my studies, where I achieved a 4.0 GPA every semester. In 2019, I transferred to Georgia State University to continue my education and broaden my horizons.`,
  professional: `At the outset of college, my understanding of the journey was simple: graduate, then find a job. However, I quickly realized the immense value of hands-on experience, which led me to pursue multiple internships. My first roles were split between an acquisitions advisory firm and the finance department of a sponsor-backed software startup in Atlanta. These experiences helped me bridge the gap between the world of equity ownership and corporate finance.

These initial internships paved the way for an opportunity at a hedge fund in Atlanta, which operates closely with the Lloyd’s of London market. Here, I sharpened my financial modeling and pitching skills through analysis and market research. Following this, I joined a Fixed Income trading desk in Atlanta, where I learned to code from scratch and contributed to optimizing internal processes.

In the summer of 2021, I took part in Truist Securities' Corporate and Investment Bank Summer Analyst Program, a role that allowed me to weave together the knowledge I had gained across various sectors. After graduation, I returned to Truist full-time in January 2022 as a Corporate and Investment Banking Analyst within the Credit Delivery group.

During my time at Truist, I’ve developed the skills to evaluate pro-rata fully underwritten and best-efforts debt-financed transactions, create financial models for complex business models, and compile memos for the investment committee and internal risk approval chains for companies ranging from Investment Grade to High Yield profiles. My role has also fueled my leadership growth, as I’ve taken on greater responsibility over time. Beyond my day-to-day duties, I’ve led the Summer Intern training program, served as the main instructor for our internally used CIB model (teaching best modeling practices from Analysts to Managing Directors), and even built a new internal model used across the entire Corporate and Investment Bank.

Today, I am an Associate within the Leveraged and Sponsor Credit Delivery Technology industry team at Truist, primarily focused on highly levered and sponsor-backed relationships in addition to assisting in preparing memos related to Term Loan B (TLB) distribution risk.
`,
  hobbies: `While working at a software startup, I taught myself VBA and developed spreadsheets to automate back-office processes. This initial dive into coding sparked a deeper interest that grew during my time at a Fixed Income trading desk, where I began teaching myself Python. What started as a personal curiosity quickly became an obsession to explore every corner of this new sandbox.

I began experimenting with Raspberry Pi and Servo motors, building simple robots. One of my early projects involved creating a robot to scrape the SEC’s EDGAR database for insider trading data. From there, I delved into quantitative trading strategies for equities and even figured out how to increase engagement on TikTok.

My passion for learning led me further into web development, database management, and mobile app creation. I’ve since built my own website from scratch using React, and developed iOS and Android apps, using tools like React-Native, Node.js, JavaScript, SQL, and Python. Software development has become my creative outlet—Netflix is rarely on, but my code editor is always open.`,
};

export default textInput;
